import "./App.css";
import { useEffect, useState } from "react";
import Orakelpanel from "./components/Orakelpanel";

import { getToken } from "firebase/messaging";
import { messaging } from "./firebase-messaging-sw";
import Header from "./components/Header";
import Login from "./components/Login";
import Subheader from "./components/Subheader";

function App() {
	const [texte, settexte] = useState(null);
	const [favorites, setfavorites] = useState(null);
	const [isList, setisList] = useState("alle");

	async function getTexte() {
		const response = await fetch(
			"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle.json"
		);
		const data = await response.json();
		console.log(data);
		settexte(data);
		return data;
	}
	async function getFavorites() {
		const username = localStorage.getItem("user");
		const response = await fetch(
			`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/user/${username}/favorites.json`
		);
		const data = await response.json();
		console.log(data);
		setfavorites(data);

		return data;
	}

	useEffect(() => {
		getTexte();
		getFavorites();
		getToken(messaging, {
			vapidKey:
				"BBeHo7HaO6eanTjlwUVRC1cu70Y2hoessbvdhjrJeu_tCUkb6IaWV5-Q5j4bMWorezvbxGkRajy35W4O43IeWnI",
		})
			.then((currentToken) => {
				if (currentToken) {
					fetch(
						"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/notifications.json"
					).then((response) => {
						response.json().then((data) => {
							for (const key in data) {
								if (data[key] === currentToken) {
									return;
								}
							}
							fetch(
								"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/notifications.json",
								{ method: "POST", body: JSON.stringify(currentToken) }
							);
						});
					});
				} else {
					// Show permission request UI
					console.log(
						"No registration token available. Request permission to generate one."
					);
					// ...
				}
			})
			.catch((err) => {
				console.log("An error occurred while retrieving token. ", err);
				// ...
			});
	}, []);
	if (localStorage.getItem("isfree")) {
		const now = Date.now();
		const old = localStorage.getItem("date");
		if (now - old > 3600000) {
			localStorage.removeItem("isfree");
			window.location.reload();
		}
		return (
			<>
				<Header />
				<Subheader setisList={setisList} />
				<div className="App">
					<Orakelpanel
						isList={isList}
						texte={texte}
						favorites={favorites}
						fetch={getTexte}
					/>
				</div>
			</>
		);
	} else {
		return <Login />;
	}
}

export default App;
