import classes from "./CommentSection.module.css";
import Comment from "./Comment";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CommentSection = (props) => {
	const { text } = props;
	const user = localStorage.getItem("user");
	const [isOpen, setisOpen] = useState(false);
	const [comments, setcomments] = useState(text.comments);
	const [isLoading, setIsLoading] = useState(false);
	const inputRef = useRef();

	const onClickHandler = async () => {
		setIsLoading(true);

		let commentsList = [...comments];
		commentsList.push({
			user: user,
			message: inputRef.current.value,
			date: Date.now(),
		});
		await fetch(
			`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle/${text.id}/comments.json`,
			{ method: "PUT", body: JSON.stringify(commentsList) }
		);
		setcomments(commentsList);
		setIsLoading(false);
	};

	return (
		<div className={classes.commentSection}>
			{!isOpen && (
				<h2 onClick={() => setisOpen(!isOpen)}>
					{comments.length === 0
						? "keine Kommentare..."
						: `${comments.length} Kommentare...`}
				</h2>
			)}
			{isOpen && (
				<h2 onClick={() => setisOpen(!isOpen)}>
					{comments.length === 0
						? "keine Kommentare"
						: `${comments.length} Kommentare`}
				</h2>
			)}
			{isOpen &&
				comments &&
				comments.map((comment) => (
					<Comment
						user={comment.user}
						message={comment.message}
						date={comment.date}
					/>
				))}
			{isOpen && (
				<>
					<textarea ref={inputRef} placeholder="Schreibe einen Kommentar..." />
					{!isLoading && <button onClick={onClickHandler}>posten</button>}
					{isLoading && (
						<button>
							<FontAwesomeIcon className={classes.isLoading} icon={faSpinner} />
						</button>
					)}
				</>
			)}
		</div>
	);
};

export default CommentSection;
