import classes from "./Comment.module.css";

const Comment = (props) => {
	const { user, message, date } = props;

	return (
		<div className={classes.comment}>
			<div className={classes.header}>
				<div className={classes.avatar}>{user.slice(0, 1)}</div>
				<div>
					<h3>{user}</h3>
					<figure>{date}</figure>
				</div>
			</div>
			<p>{message}</p>
		</div>
	);
};

export default Comment;
