import { useEffect, useState } from "react";
import classes from "./Orakelpanel.module.css";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLanguage,
	faTrashAlt,
	faHeart as solidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import CommentSection from "./CommentSection";

const TextCard = (props) => {
	const { text, getItems } = props;
	const user = localStorage.getItem("user");
	const [isClicked, setisClicked] = useState(false);
	const [titleClicked, settitleClicked] = useState(false);
	const [isFavorite, setisFavorite] = useState(false);
	const [rating, setrating] = useState(text.rating[user]);

	useEffect(() => {
		if (text.isFavorite) {
			setisFavorite(true);
			console.log(text.title);
		}
	}, []);

	const onClickHandler = () => {
		if (titleClicked) {
			setisClicked(!isClicked);
		}
	};

	const onTitleClickHandler = () => {
		settitleClicked(!titleClicked);
	};

	const isFavoriteHandler = async () => {
		setisFavorite(!isFavorite);
		const username = localStorage.getItem("user");
		console.log(text.id);
		if (isFavorite) {
			const response = await fetch(
				`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/user/${username}/favorites.json`
			);
			let data = await response.json();
			for (const key in data) {
				console.log(data[key], text.id);
				if (data[key] === text.id) {
					delete data[key];
				}
			}
			fetch(
				`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/user/${username}/favorites/.json`,
				{
					method: "PUT",
					body: JSON.stringify(data),
					headers: { "Content-Type": "application/json" },
				}
			);
		} else {
			fetch(
				`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/user/${username}/favorites/.json`,
				{
					method: "POST",
					body: JSON.stringify(text.id),
					headers: { "Content-Type": "application/json" },
				}
			);
		}
	};
	const onDeleteHandler = async () => {
		const response = await fetch(
			"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle.json"
		);

		const data = await response.json();
		for (const key in data) {
			if (key === text.id) {
				delete data[key];
			}
		}

		console.log(data);
		await fetch(
			"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle.json",
			{
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		await getItems();
	};
	const ratingHandler = async (rating) => {
		let ratings = text.rating;
		ratings[user] = rating;
		let count = 0;
		let values = [];
		for (const key in ratings) {
			count++;
			values.push(ratings[key]);
		}
		const sumvalues = values.reduce((a, b) => a + b, 0);
		const ratingAvg = (sumvalues / values.length).toFixed(2);

		setrating(rating);
		await fetch(
			`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle/${text.id}/rating.json`,
			{
				method: "PUT",
				body: JSON.stringify(ratings),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		await fetch(
			`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle/${text.id}/avgRating.json`,
			{
				method: "PUT",
				body: JSON.stringify(ratingAvg),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		await fetch(
			`https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app/oracle/${text.id}/countRating.json`,
			{
				method: "PUT",
				body: JSON.stringify(count),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	};
	return (
		<div className={classes.card}>
			<div className={classes.head}>
				<div>
					<h2>{text.title.replace("<pad>", "").replace("</s>", "")}</h2>
					<div className={classes.rating}>
						<ReactStars
							count={5}
							value={rating}
							size={24}
							activeColor="rgb(146, 182, 49)"
							onChange={ratingHandler}
						/>
						<p>&#8709; {text.avgRating} </p>
					</div>
				</div>
				<div className={classes.actions}>
					{isFavorite && (
						<button className={classes.favorite} onClick={isFavoriteHandler}>
							<FontAwesomeIcon
								style={{ color: "firebrick" }}
								icon={solidHeart}
							/>
						</button>
					)}
					{!isFavorite && (
						<button className={classes.favorite} onClick={isFavoriteHandler}>
							<FontAwesomeIcon style={{ color: "teal" }} icon={faHeart} />
						</button>
					)}
					<button onClick={onClickHandler}>
						<FontAwesomeIcon icon={faLanguage} />
					</button>
					<button className={classes.delete} onClick={onDeleteHandler}>
						<FontAwesomeIcon icon={faTrashAlt} />
					</button>
				</div>
			</div>

			<p onClick={onTitleClickHandler} className={classes.summ}>
				{text.summ}
			</p>

			{isClicked && titleClicked && (
				<p>
					{text.pre && text.pre}
					{text.text.split("\n").map((item, key) => {
						return (
							<span key={key}>
								{item}
								<br />
							</span>
						);
					})}
				</p>
			)}
			{!isClicked && titleClicked && (
				<p>
					{text.translated.split("\n").map((item, key) => {
						return (
							<span key={key}>
								{item}
								<br />
							</span>
						);
					})}
				</p>
			)}
			{titleClicked && <CommentSection text={text} />}
		</div>
	);
};

export default TextCard;
