import classes from "./Header.module.css";

const Header = () => {
	return (
		<header>
			<nav>
				<h1>Shitblog</h1>
				<p>-Blogposts aus aller Welt-</p>
			</nav>
		</header>
	);
};

export default Header;
