import classes from "./Orakelpanel.module.css";
import TextCard from "./TextCard";

const Orakelpanel = (props) => {
	const { texte, fetch, favorites, isList } = props;
	const user = localStorage.getItem("user");
	console.log(isList);
	if (texte === null) {
		return <div>Keine Textevorhanden!</div>;
	}

	let textObjects = [];

	for (const key in texte) {
		let newItem = {
			id: key,
			title: texte[key].title,
			prompt: texte[key].prompt,
			text: texte[key].text,
			translated: texte[key].translated,
			tag: texte[key].tag,
			pre: texte[key].pre,
			summ: texte[key].summ,
			isFavorite: false,
			rating: { [user]: 0 },
			avgRating: texte[key].avgRating ? texte[key].avgRating : 0,
			countRating: texte[key].countRating ? texte[key].countRating : 0,
			comments: texte[key].comments ? texte[key].comments : [],
		};

		if (texte[key].hasOwnProperty("rating")) {
			console.log(true);
			if (texte[key].rating.hasOwnProperty(user)) {
				newItem.rating[user] = texte[key].rating[user];
			}
		}

		textObjects.push(newItem);
	}

	textObjects.forEach((item, index) => {
		for (const key in favorites) {
			if (favorites[key] === item.id) {
				textObjects[index].isFavorite = true;
			}
		}
	});

	textObjects.reverse();

	if (isList === "favorit") {
		textObjects = textObjects.filter((item) => item.isFavorite === true);
	}
	if (isList === "rating") {
		textObjects = textObjects.filter((item) => item.countRating !== 0);
		textObjects.sort((a, b) => b.avgRating - a.avgRating);
		textObjects.sort((a, b) => b.count - a.count);
	}
	if (isList === "comments") {
		textObjects = textObjects.filter((item) => item.comments.length > 0);
		textObjects.sort(
			(a, b) =>
				b.comments[b.comments.length - 1].date -
				a.comments[a.comments.length - 1].date
		);
	}

	return (
		<div className={classes.panel}>
			{textObjects.map((text) => (
				<TextCard key={text.id} text={text} getItems={fetch} />
			))}
		</div>
	);
};

export default Orakelpanel;
