import { useRef } from "react";
import classes from "./Login.module.css";

const Login = () => {
	const inputRef = useRef();
	const userNameRef = useRef();
	const onClickHandler = () => {
		if (
			inputRef.current.value === "schwebräupchen666" &&
			userNameRef.current.value !== ""
		) {
			const time = Date.now();
			localStorage.setItem("isfree", "true");
			localStorage.setItem("user", userNameRef.current.value);
			localStorage.setItem("date", time);
			window.location.reload();
		} else {
			alert("Falsches Passwort oder Username!");
		}
	};

	return (
		<div className={classes.login}>
			<h1>Login for fun and action</h1>
			<div>
				<label>
					Username
					<input required ref={userNameRef} type="text"></input>
				</label>
				<label>
					Password
					<input required ref={inputRef} type="password"></input>
				</label>
				<button onClick={onClickHandler}>Login</button>
			</div>
		</div>
	);
};

export default Login;
