import classes from "./Subheader.module.css";

const Subheader = (props) => {
	const { setisList } = props;

	return (
		<div className={classes.Subheader}>
			<ul>
				<li onClick={() => setisList("alle")}>Alle</li>
				<li onClick={() => setisList("favorit")}>Favoriten</li>
				<li onClick={() => setisList("rating")}>Bestbewertet</li>
				<li onClick={() => setisList("comments")}>Neueste Kommentare</li>
			</ul>
		</div>
	);
};

export default Subheader;
