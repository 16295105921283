import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp({
	apiKey: "AIzaSyAbWdGYKRLrVztnZAhjQKhGUznQFKdJZ1U",
	authDomain: "orakel-a999a.firebaseapp.com",
	databaseURL:
		"https://orakel-a999a-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "orakel-a999a",
	storageBucket: "orakel-a999a.appspot.com",
	messagingSenderId: "807791777279",
	appId: "1:807791777279:web:471234cd85e8384635c3ca",
});

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
export const messaging = getMessaging(firebaseApp);
